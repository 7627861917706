<template>

  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <router-link class="table-card__item-number" :to="$store.getters.GET_PATHS.mainSettingsUsers + '/show/' + item.id">
          #{{item.id}}
        </router-link>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('userModule_Id.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              #{{item.id}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('userModule_Created.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('userModule_UserName.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <div class="table-row d-flex flex-wrap">
                <TableUserColumn
                    :item="{user: item}"
                />
              </div>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('userModule_Role.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.role.name.charAt(0).toUpperCase() + item.role.name.replace('_', ' ').slice(1)}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('userModule_Status.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <template v-if="item.locked">
                <StatusIcoBtn :type="'rejected'" class="mr-1"/>
                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['userModule_Blocked'])"></div>
                  {{$t('userModule_Blocked.localization_value.value')}}
                </div>


              </template>
              <template v-if="!item.locked">
                <StatusIcoBtn :type="'active'" class="mr-1"/>
                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['userModule_Active'])"></div>
                  {{$t('userModule_Active.localization_value.value')}}
                </div>
              </template>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('userModule_Balance.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <router-link :to="$store.getters.GET_PATHS.balanceHistory + `?&userId=${item.id}&userName=${item.contacts[0].user_full_name}`">
                <div class="site-link text-decoration-underline white-space-line">
                  {{item.balance | currency}}
                </div>
              </router-link>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('userModule_BalancePayoneer.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <router-link :to="$store.getters.GET_PATHS.balancePayoneerHistory + `?&userId=${item.id}&userName=${item.contacts[0].user_full_name}`">
                <div class="site-link text-decoration-underline white-space-line">
                  {{payoneerBalance | currency}}
                </div>
              </router-link>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('userModule_Frozen.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'frozen_balance'"
                  :costType="true"
                  :costCurrencyType="'dollar'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('userModule_StorName.localization_value.value')}}
            </div>
            <div class="table-card__item-info flex-wrap">
              <div class="table-row  flex-wrap" v-if="shopLinks = getUserShopsLinks(item)">
                <template v-if="shopLinks.length > 0">
                  <template v-for="(shopItem, shopIndex) in shopLinks">
                  <span :key="shopIndex">
                    <a
                        class="site-link text-decoration-underline mr-2 ws-break-spaces"
                        target="_blank"
                        :href="shopItem.link"
                        v-if="shopItem.link"
                    >
                      {{ shopItem.name }}
                    </a>
                    <span
                        class="site-link text-decoration-underline mr-2 ws-break-spaces"
                        v-else
                    >
                      {{ shopItem.name }}
                    </span>
                  </span>

                  </template>
                </template>
                <template v-else>
                  —
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <LinkButton
                :value="`${$t('userModule_ViewTransactions.localization_value.value')} (${getUserTransactionsCount(item.user_counter)})`"
                :link="$store.getters.GET_PATHS.cusPayments + `?userId=${item.id}` +
                  (item.user_personal_contact.user_full_name ? `&userName=${item.user_personal_contact.user_full_name}` : '')"
            />
          </div>
          <div class="table-card__item-btn-i"
               v-if="!isSubAdmin">
            <LinkButton
                :value="$t('common_edit.localization_value.value')"
                :type="'edit'"
                :link="$store.getters.GET_PATHS.mainSettingsUsers + '/' + item.id + `${item.isAdmin ? '?adminType=true' : ''}`"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        Show More
      </div>
    </div>
  </div>
</template>

<script>
import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";
import StatusIcoBtn from "@/components/UI/status/StatusIcoBtn/StatusIcoBtn";
import TableUserColumn from "@/components/coreComponents/TableComponents/TableUserColumn/TableUserColumn";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import {usersManageMixin} from "@/mixins/usersMixins/usersManageMixin";
export default {
  name: "UsersTableMobile",
  components: {ValueHelper, TableUserColumn, StatusIcoBtn, LinkButton},

  mixins: [usersManageMixin],

  props: {
    item: Object,
    payoneerBalance: Object,
  },

  data() {
    return {
      show: false,
    }
  }

}
</script>

<style scoped>

</style>