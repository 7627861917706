<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup"
  >
    <template slot="header">
      {{$t('common_userReportOnDate.localization_value.value')}}
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="custom-row">
          <div class="custom-col">
            <DatePickerDefault
                :label="$t('requestReturn_Date.localization_value.value')"
                v-bind:class="{'ui-no-valid': validation.date}"
                :errorTxt="$t(`${validationTranslate.date}.localization_value.value`)"
                :error="validation.date"
                v-model="date"
            >
              <template slot="body">
                <date-picker
                    v-model="date"
                    ref="datePicker"
                    valueType="format"
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="'mm/dd/yy'"
                ></date-picker>
              </template>
            </DatePickerDefault>
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap">
        <span
            class="site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3"
            @click="$emit('close')"
        >
          Cancel
        </span>
        <MainButton
            :value="$t('common_export.localization_value.value')"
            class="import-transaction__btn-i"
            @click.native="exportUsers"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DatePickerDefault from "@/components/UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";

  export default {
    name: "UserReportOnDatePopup",
    components: {
      DatePickerDefault,
      Modal,
      MainButton,
      DatePicker,
    },

    mixins: [routeFilter],

    data() {
      return {
        date: '',
        validationTranslate: {
          date: '',
        },
        validation: {
          date: false,
        }
      }
    },

    methods: {

      exportUsers() {
        if(this.date.length === 0) {
          this.validation.date = true
          this.validationTranslate.date = 'error_required'
          return
        } else {
          this.validation.date = false
          this.validationTranslate.date = ''
        }

        let data = {
          balance_date: this.$moment(this.date).format("YYYY-MM-DD")
        }
        this.$store.dispatch('userBalanceReportOnDate', data).then(response => {
          this.createDownloadLink({data: response.data, name: `User balance report on date.xlsx`, type: 'xlsx'})
        })
      },
    }
  }
</script>

<style scoped>

  .mw-305 {
    max-width: 305px;
  }
</style>
