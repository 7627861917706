<template>
  <div>
    <UsersHead
        :showFilter="showFilter"
        :countFilterParams="countFilterParams"
        @toggleFilter="toggleFilter"
        @selectAll="selectAll"
        @filter="$emit('filter')"
        @reload="$emit('reload')"
        @disconnectAmazonAPI="$emit('disconnectAmazonAPI')"
    />

    <div class="table-filter-wrap">

      <UsersFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <UsersTable
          :countFilterParams="countFilterParams"
          :selectedNow="selectedNow"
          @changeFilter="data => $emit('changeFilter', data)"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
          @downloadFiles="type => $emit('downloadFiles', type)"
      />
    </div>
  </div>
</template>

<script>


  import UsersHead from "./UsersHead/UsersHead";
  import UsersTable from "./UsersTable/UsersTable";
  import UsersFilter from "./UsersFilter/UsersFilter";

  export default {
    name: "UsersTableAdmin",

    components: {
      UsersHead,
      UsersTable,
      UsersFilter,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
    },

    data(){
      return{
        showFilter: false,
        selectedNow: false,
      }
    },

    watch: {
      typeShops(newVal) {
        this.typeShops = newVal
      },
      filterGetParams(newVal) {
        this.filterGetParams = newVal
      },
      countFilterParams(newVal) {
        this.countFilterParams = newVal
      },
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      selectAll(val) {
        this.selectedNow = val
      },
    }
  }
</script>

<style scoped>

</style>

