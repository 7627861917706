<template>
  <div class="fragment">
    <template v-if="$store.getters.getUsers.length > 0 && !$store.getters.getUsersLoading">
      <div class="site-table-wrap small-table products-table"
        v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
          'userModule_Id',
          'userModule_Created',
          'userModule_UserName',
          'userModule_Role',
          'userModule_Status',
          'userModule_Balance',
          'userModule_Frozen',
          'userModule_StorName',
          'userModule_ViewTransactions',
        ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th></th>
            <th>{{$t('userModule_Id.localization_value.value')}}</th>
            <th>{{$t('userModule_Created.localization_value.value')}}</th>
            <th>{{$t('userModule_UserName.localization_value.value')}}</th>
            <th>{{$t('userModule_Role.localization_value.value')}}</th>
            <th>{{$t('userModule_Status.localization_value.value')}}</th>
            <th>{{$t('userModule_Balance.localization_value.value')}}</th>
            <th>{{$t('userModule_BalancePayoneer.localization_value.value')}}</th>
            <th>{{$t('userModule_Frozen.localization_value.value')}}</th>
            <th>{{$t('userModule_StorName.localization_value.value')}}</th>
            <th>{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr
                  v-for="(item, index) in $store.getters.getUsers"
                  :key="index">
            <td>
              <DefaultCheckbox
                  class="empty-label"
                  :selectedNow="selectedNow"
                  :dataValue="item.id"
              />
            </td>
            <td>
              <router-link class="table-link btn-style" :to="$store.getters.GET_PATHS.mainSettingsUsers + '/show/' + item.id">
                #{{item.id}}
              </router-link>
            </td>
            <td>
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </td>
            <td>
              <TableUserColumn
                      :item="{user: item}"
                      :fromUsersTable="true"
                      @filterByUser="filterByUser(item.id)"
              />
            </td>
            <td>
              {{item.role.name.charAt(0).toUpperCase() + item.role.name.replace('_', ' ').slice(1)}}
            </td>
            <td>
              <template v-if="item.locked">
                <StatusIcoBtn :type="'rejected'" class="mr-1"/>
                <span v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['userModule_Blocked'])"></div>
                  {{$t('userModule_Blocked.localization_value.value')}}
                </span>


              </template>
              <template v-if="!item.locked">
                <StatusIcoBtn :type="'active'" class="mr-1"/>
                <span v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['userModule_Active'])"></div>
                  {{$t('userModule_Active.localization_value.value')}}
                </span>


              </template>
            </td>
            <td>
              <router-link :to="$store.getters.GET_PATHS.balanceHistory + `?&userId=${item.id}&userName=${item.contacts[0].user_full_name}`">
                <div class="site-link text-decoration-underline white-space-line">
                  {{item.balance | currency}}
                </div>
              </router-link>
            </td>
            <td>
              <router-link :to="$store.getters.GET_PATHS.balancePayoneerHistory + `?&userId=${item.id}&userName=${item.contacts[0].user_full_name}`">
                <div class="site-link text-decoration-underline white-space-line">
                  {{getPayoneerBalance(item)| currency}}
                </div>
              </router-link>
            </td>
            <td>
              <ValueHelper
                      :value="item"
                      :deep="'frozen_balance'"
                      :costType="true"
                      :costCurrencyType="'dollar'"
              />
            </td>
            <td>
              <div class="table-row  flex-wrap" v-if="shopLinks = getUserShopsLinks(item)">
                <template v-if="shopLinks.length > 0">
                  <template v-for="(shopItem, shopIndex) in shopLinks">
                  <span :key="shopIndex">
                    <a
                        class="site-link text-decoration-underline mr-2 ws-break-spaces"
                        target="_blank"
                        :href="shopItem.link"
                        v-if="shopItem.link"
                    >
                      {{ shopItem.name }}
                    </a>
                    <span
                        class="site-link text-decoration-underline mr-2 ws-break-spaces"
                        v-else
                    >
                      {{ shopItem.name }}
                    </span>
                  </span>

                  </template>
                </template>
                <template v-else>
                  —
                </template>

<!--                <span class="site-link text-decoration-underline mr-2">-->
<!--                 myhappyweddingday.com-->
<!--                  -->
<!--                </span>-->

              </div>
            </td>
            <td class="manage-row">
              <div class="table-right table-manage-list table-manage-list--small">

                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                  <template slot="item">
                    <LinkButton
                            :value="`${$t('userModule_ViewTransactions.localization_value.value')} (${getUserTransactionsCount(item.user_counter)})`"
                            :link="$store.getters.GET_PATHS.cusPayments + `?userId=${item.id}` +
                            (item.user_personal_contact.user_full_name ? `&userName=${item.user_personal_contact.user_full_name}` : '')"
                    />
                  </template>
<!--                  <template slot="item">-->
<!--                    <LinkButton-->
<!--                            :value="'Correct Products'"-->
<!--                            :link="$store.getters.GET_PATHS.mainSettingsUsersCountCorrect"-->
<!--                    />-->
<!--                  </template>-->
                  <template slot="item">
                    <LinkButton
                        v-if="!isSubAdmin || isSubAdmin && _.has(currentPermissions, PERMISSIONS.USERS_EDIT)"
                        :value="$t('common_edit.localization_value.value')"
                        :type="'edit'"
                        :link="$store.getters.GET_PATHS.mainSettingsUsers + '/' + item.id + `${item.isAdmin ? '?adminType=true' : ''}`"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-else>
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getUsers"
                 :key="index">
              <UsersTableMobile
                  :item="item"
                  :selectedNow="selectedNow"
                  :payoneerBalance="getPayoneerBalance(item)"
              />
            </div>
<!--            <div class="table-card__item">-->
<!--              <div class="table-card__item-content"-->
<!--                   :class="{show: show2}"-->
<!--              >-->
<!--                <div class="table-card__item-head">-->
<!--                  <div class="table-card__item-head-row">-->
<!--                    <div class="table-card__item-number">-->
<!--                      #3456363-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="table-card__item-head-row">-->
<!--                    <div class="table-card__item-head-txt">-->
<!--                      03 Aug, 2021-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <transition name="slide">-->
<!--                  <div class="table-card__item-body"-->
<!--                       v-if="show2"-->
<!--                  >-->
<!--                    <div class="table-card__item-row custom-row">-->
<!--                      <div class="table-card__item-col custom-col custom-col&#45;&#45;50">-->
<!--                        <div class="table-card__item-label">-->
<!--                          ID-->
<!--                        </div>-->
<!--                        <div class="table-card__item-info">-->
<!--                          #3456363-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="table-card__item-col custom-col custom-col&#45;&#45;50">-->
<!--                        <div class="table-card__item-label">-->
<!--                          Created-->
<!--                        </div>-->
<!--                        <div class="table-card__item-info">-->
<!--                          03 Aug, 2021-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="table-card__item-col custom-col">-->
<!--                        <div class="table-card__item-label">-->
<!--                          User Name-->
<!--                        </div>-->
<!--                        <div class="table-card__item-info">-->
<!--                          <div class="table-row d-flex flex-wrap">-->
<!--                            <StatusIcoBtn-->
<!--                                    class="mr-1"-->
<!--                                    :type="'delivered'"-->
<!--                            />-->
<!--                            <LabelCircle-->
<!--                                    class="oval mr-1"-->
<!--                                    :color="'blue'"-->
<!--                                    :value="'NEW'"-->
<!--                            />-->
<!--                            <span class="table-link btn-style mr-1 white-space-line"-->
<!--                                  @click="changeStatisticPopup(true)"-->
<!--                            >-->
<!--                          Oksana Kisel-->
<!--                        </span>-->

<!--                            <span class="brick-btn mr-1">-->
<!--                          FBM-->
<!--                        </span>-->
<!--                            <span class="brick-btn mr-1">-->
<!--                          ATM-->
<!--                        </span>-->

<!--                            <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton"-->
<!--                                    class="alone more-180 ml-3 mb-1"-->
<!--                            >-->
<!--                              <template slot="item">-->
<!--                                <LinkButton-->
<!--                                        :value="'Mail with Client'"-->
<!--                                        :type="'letter'"-->
<!--                                        @click.native="changeSendEmailPopup(true)"-->
<!--                                />-->
<!--                              </template>-->
<!--                              <template slot="item">-->
<!--                                <LinkButton-->
<!--                                        :value="'View Comments'"-->
<!--                                        :type="'cloud-msg'"-->
<!--                                        @click.native="changeCommentsPopup(true)"-->
<!--                                />-->
<!--                              </template>-->
<!--                              <template slot="item">-->
<!--                                <LinkButton-->
<!--                                        :value="'Last Five IP'"-->
<!--                                        :type="'pin'"-->
<!--                                        @click.native="changeLastFiveIPPopup(true)"-->
<!--                                />-->
<!--                              </template>-->
<!--                              <template slot="item">-->
<!--                                <LinkButton-->
<!--                                        :value="'Client’s Statistics'"-->
<!--                                        :type="'statistic'"-->
<!--                                />-->
<!--                              </template>-->
<!--                            </ManagerButton>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      &lt;!&ndash;<div class="table-card__item-col custom-col custom-col&#45;&#45;50">&ndash;&gt;-->
<!--                      &lt;!&ndash;<div class="table-card__item-label">&ndash;&gt;-->
<!--                      &lt;!&ndash;Status&ndash;&gt;-->
<!--                      &lt;!&ndash;</div>&ndash;&gt;-->
<!--                      &lt;!&ndash;<div class="table-card__item-info">&ndash;&gt;-->
<!--                      &lt;!&ndash;<StatusIcoBtn :type="'rejected'" class="mr-1"/> Blocked&ndash;&gt;-->
<!--                      &lt;!&ndash;</div>&ndash;&gt;-->
<!--                      &lt;!&ndash;</div>&ndash;&gt;-->
<!--                      <div class="table-card__item-col custom-col custom-col&#45;&#45;50">-->
<!--                        <div class="table-card__item-label">-->
<!--                          Balance-->
<!--                        </div>-->
<!--                        <div class="table-card__item-info">-->
<!--                          <router-link :to="$store.getters.GET_PATHS.balanceHistory">-->
<!--                        <span class="site-link text-decoration-underline white-space-line">-->
<!--                          -$ 15.665-->
<!--                        </span>-->
<!--                          </router-link>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="table-card__item-col custom-col custom-col&#45;&#45;50">-->
<!--                        <div class="table-card__item-label">-->
<!--                          Frozen-->
<!--                        </div>-->
<!--                        <div class="table-card__item-info">-->
<!--                          $ 10-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="table-card__item-col custom-col ">-->
<!--                        <div class="table-card__item-label">-->
<!--                          Stor Name-->
<!--                        </div>-->
<!--                        <div class="table-card__item-info flex-wrap">-->
<!--                      <span class="site-link text-decoration-underline mr-1">-->
<!--                        ukrainian treasures.com-->
<!--                      </span>-->
<!--                          <span class="site-link text-decoration-underline mr-1">-->
<!--                        another_store.ua-->
<!--                      </span>-->

<!--                          <v-popover-->
<!--                                  class="site-tooltip"-->
<!--                                  offset="5"-->
<!--                                  placement="top"-->
<!--                                  trigger="hover"-->
<!--                          >-->
<!--                        <span>-->
<!--                          <CountCircle-->
<!--                                  :value="'+3'"-->
<!--                          />-->
<!--                        </span>-->
<!--                            <template slot="popover">-->
<!--                              <p><b>All sotres</b></p>-->
<!--                              <p>-->
<!--                                <span class="table-link fsz11 btn-style tran-0">Another_sotre_number.com</span>,-->
<!--                                <span class="table-link fsz11 btn-style tran-0">ukrainian treasures.com</span>,-->
<!--                                <span class="table-link fsz11 btn-style tran-0">ukrainian treasures_one_more_store.com</span>,-->
<!--                              </p>-->
<!--                            </template>-->
<!--                          </v-popover>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->

<!--                    <div class="table-card__item-btn">-->
<!--                      <div class="table-card__item-btn-i">-->
<!--                        <LinkButton-->
<!--                                :value="'View Transactions (34)'"-->
<!--                        />-->
<!--                      </div>-->
<!--                      <div class="table-card__item-btn-i">-->
<!--                        <LinkButton-->
<!--                                :value="'Correct Products'"-->
<!--                                :link="$store.getters.GET_PATHS.mainSettingsUsersCountCorrect"-->
<!--                        />-->
<!--                      </div>-->
<!--                      <div class="table-card__item-btn-i">-->
<!--                        <LinkButton-->
<!--                                :value="'Edit'"-->
<!--                                :type="'edit'"-->
<!--                                :link="$store.getters.GET_PATHS.mainSettingsUsersEdit"-->
<!--                        />-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </transition>-->
<!--                <div class="table-card__item-footer">-->
<!--                  <div class="table-card__item-show"-->
<!--                       @click="show2 = !show2"-->
<!--                  >-->
<!--                    Show More-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>

      </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
                  v-if="$store.getters.getUsersCommonList.next_page_url !== null && $store.getters.getUsers.length > 0"
                  @click.native="$emit('showMore')"
                  v-bind:class="{'disabled-btn' : $store.getters.getNextUsersPage}"
                  :count="$store.getters.getUsersCommonList.total - $store.getters.getUsersForPage < $store.getters.getUsersForPage ?
                  $store.getters.getUsersCommonList.total - $store.getters.getUsersForPage:
                  $store.getters.getUsersForPage"
          />
          <ExportBtn
              @downloadFiles="type => $emit('downloadFiles', type)"
                  class="table-bottom-btn__right"/>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getUsersLoading === false && $store.getters.getUsers.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

    <!--<ProductsToWarehouse @close="closePopup" v-if="isModalProductsToWarehouse"/>-->

    <CommentsPopup
        v-if="isModalCommentsPopup"
        @close="changeCommentsPopup(false)"
    />

    <SendEmailPopup
        v-if="isModalSendEmailPopup"
        @close="changeSendEmailPopup(false)"
    />

    <LastFiveIPPopup
        v-if="isModalLastFiveIPPopup"
        @close="changeLastFiveIPPopup(false)"
    />

    <StatisticPopup
        v-if="isModalStatisticPopup"
        @close="changeStatisticPopup(false)"
    />


  </div>
</template>

<script>
  // import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import StatusIcoBtn from "../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  // import LabelCircle from "../../../../../../coreComponents/LabelCircle/LabelCircle";
  import CommentsPopup from "../../../../popups/CommentsPopup/CommentsPopup";
  import SendEmailPopup from "../../../../../../coreComponents/TableComponents/TableUserColumn/popups/SendEmailPopup/SendEmailPopup";
  import LastFiveIPPopup from "../../../../popups/LastFiveIPPopup/LastFiveIPPopup";
  import StatisticPopup from "../../../../popups/StatisticPopup/StatisticPopup";
  // import CountCircle from "../../../../../../coreComponents/CountCircle/CountCircle";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import UsersTableMobile
    from "@/components/modules/UsersModule/components/UsersTable/UsersTableAdmin/UsersTable/UsersTableMobile/UsersTableMobile";
  import {usersManageMixin} from "@/mixins/usersMixins/usersManageMixin";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import {PERMISSIONS} from "@/staticData/permissionsStatic";


  export default {
    name: "UsersTable",

    components: {
      DefaultCheckbox,
      UsersTableMobile,
      ValueHelper,
      TableUserColumn,
      NoResult,
      // MainButton,
      // DefaultInput,
      ShowMore,
      ExportBtn,
      LinkButton,
      ManagerButton,
      // ProductsToWarehouse,
      StatusIcoBtn,
      // LabelCircle,
      CommentsPopup,
      SendEmailPopup,
      LastFiveIPPopup,
      StatisticPopup,
      // CountCircle,
    },

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
    },

    mixins: [mixinDetictingMobile, usersManageMixin],

    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,

        // isModalProductsToWarehouse: false,

        show1: false,
        show2: false,

        isModalCommentsPopup: '',
        isModalSendEmailPopup: '',
        isModalLastFiveIPPopup: '',
        isModalStatisticPopup: '',
        isModalInfoIDPopup: '',

        PERMISSIONS: PERMISSIONS,
      }
    },

    methods: {

      getPayoneerBalance(item) {
        return this._.find(item?.user_balances, {payment_system: 'payoneer'})?.balance
      },

      closePopup(){
        // this.isModalProductsToWarehouse = false
      },

      changeCommentsPopup(val){
        this.isModalCommentsPopup = val
      },
      changeSendEmailPopup(val){
        this.isModalSendEmailPopup = val
      },
      changeLastFiveIPPopup(val){
        this.isModalLastFiveIPPopup = val
      },
      changeStatisticPopup(val){
        this.isModalStatisticPopup = val
      },
      changeInfoIDPopup(val){
        this.isModalInfoIDPopup = val
      },


      filterByUser(id) {
        this.$emit('changeFilter', {userId: id})
      },

    }

  }
</script>

<style lang="scss" scoped>

  /*.site-table thead th,*/
  /*.site-table tbody td{*/
    /*padding-right: 5px;*/
    /*padding-left: 5px;*/
  /*}*/

  .manage-row,
  .products-table .table-manage-list .manager-btn{
    width: 390px;

  }

  @media(max-width: 1600px){
    .manage-row,
    .products-table .table-manage-list .manager-btn{
      width: 80px;
    }
  }

  /*.site-table thead th:first-child,*/
  /*.site-table tbody td:first-child{*/
    /*padding-right: 0;*/
  /*}*/

  /*.site-table thead th:nth-child(2),*/
  /*.site-table tbody td:nth-child(2){*/
    /*padding-left: 0;*/
  /*}*/

  .products-table .site-table thead th:last-child,
  .products-table .site-table tbody td:last-child {
    padding-right: 0;
  }

  .products-table .ellipsis{
    max-width: 290px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;
  }

  @media(max-width: 1600px){
    .hide-1600{
      display: none;
    }
  }

</style>
