import {DEFAULT_SHOP_TYPES} from "@/staticData/staticVariables";

export const usersManageMixin = {

  data() {
    return {
    }
  },

  methods: {
    getUserTransactionsCount(val) {
      return val ? val?.paypal_transactions_count : 0
    },

    getUserShopsLinks(val) {
      if (!val?.shops.length)
        return []

      return val.shops.map(shop => {
        switch (shop.shop_type.name) {
          case DEFAULT_SHOP_TYPES.etsy.type: {
            return {
              link: `https://www.etsy.com/shop/${shop.shop_name}`,
              name: shop?.shop_name
            }
          }
          case DEFAULT_SHOP_TYPES.ebay.type: {
            return {
              link: `https://ebay.com/usr/${shop.shop_name}`,
              name: shop?.shop_name
            }
          }
          default: {
            return {
              link: shop?.shop_param?.remote_shop_url || shop?.shop_param?.shop_link || null,
              name: shop?.shop_name
            }
          }
        }
      })
    }
  },


}
