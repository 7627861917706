<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item">
        <DefaultInput
            :label="'ID'"
            :type="'text'"
            v-model="userId"
        />
      </div>

      <div class="table-filter__item"
           v-if="$store.getters.getIsAdminRights === 'admin'"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>
        <SearchSelect
                :options="options"
                :label="$t('common_user.localization_value.value')"
                :optionsLabel="'email.contacts[0]'"
                :functionSearch="functionSearchUserFilter"
                @change="changeUsersFilter"
                :selected="userName"
                :typeSelect="'users'"
                :userEmail="true"
        />
      </div>

<!--      <div class="table-filter__item">-->
<!--        <DefaultInput-->
<!--            :label="'Status'"-->
<!--            :type="'text'"-->
<!--            v-model="cellsFilter"-->
<!--        />-->
<!--      </div>-->

      <div class="table-filter__item">
        <DefaultInput
            :label="$t('userModule_Balance.localization_value.value')"
            :type="'text'"
            v-model="balance"
        />
      </div>

      <div class="table-filter__item">
        <DefaultInput
            :label="$t('userModule_Frozen.localization_value.value')"
            :type="'text'"
            v-model="frozen"
        />
      </div>

      <div class="table-filter__item">
        <DefaultInput
            :label="$t('userModule_StorName.localization_value.value')"
            :type="'text'"
            v-model="shopName"
        />
      </div>

      <div class="table-filter__item">
        <DatePickerDefault
            :label="$t('userModule_Created.localization_value.value')"
            v-model="date"
        >
          <template slot="body">
            <date-picker
                v-model="date"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['userModule_Locked',])"></div>
        <DefaultSelect
            class="w-100"
            :options="optionsLocked"
            :label="$t('userModule_Locked.localization_value.value')"
            :optionsLabel="'name'"
            :selected="_.find(optionsLocked, {value: locked})"
            @change="(val) => {this.locked = val.value}"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_country'])"></span>

        <DefaultSelect
            :options="optionsCountries"
            :label="$t('common_country.localization_value.value')"
            :selected="getCountrySelected()"
            :optionsLabel="'label'"
            @change="changeCountry"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['userModule_Role'])"></span>

        <DefaultSelect
            :options="optionsRoles"
            :label="$t('userModule_Role.localization_value.value')"
            :selected="getRoleSelected()"
            :optionsLabel="'name'"
            @change="changeRole"
        />
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
            'userModule_lastLogin',
            'userModule_10Days',
            'userModule_30Days',
            'userModule_60Days',
            'userModule_90Days',
            ])"></span>

        <DefaultSelect
            :options="LAST_LOGIN_DAYS_ARRAY"
            :label="$t('userModule_lastLogin.localization_value.value')"
            :selected="getLastLoginSelected()"
            :otherValue="'translation'"
            @change="changeLastLogin"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['userModule_orderFbmFee'])"></span>

        <DefaultSelect
            :options="getOrderFbmFee"
            :label="$t('userModule_orderFbmFee.localization_value.value')"
            :selected="getOrderFbmFeeSelected"
            :optionsLabel="'label'"
            @change="changeOrderFbmFee"
        />
      </div>
    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
  import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
  import {filterCountriesUSA} from "../../../../../../../services/filterCountries";
  import {LAST_LOGIN_DAYS_ARRAY} from "../../../../../../../staticData/staticVariables";

  export default {
    name: "UsersFilter",
    components: {
      DefaultSelect,
      SearchSelect,
      FilterBlock,
      MainButton,
      DatePickerDefault,
      DatePicker,
      DefaultInput,
    },

    props: [
      'showFilter',
      'filterGetParams',
    ],

    mixins: [filterFunc, usersFunc],

    computed: {
      getOrderFbmFee() {
        return this.$store.getters.getUsersCommonList?.filter?.by_order_fbm_fee?.list || []
      },

      getOrderFbmFeeSelected() {
        return this._.find(this.$store.getters.getUsersCommonList?.filter?.by_order_fbm_fee?.list, {value: this.byFBMFee})
      }

    },

    mounted() {
      this.$store.dispatch('fetchCountries').then(() => {
        let countries = this._.toArray(this.$store.getters.getCountries)
        filterCountriesUSA(countries)
        this.optionsCountries = countries;
        this.getCountrySelected()
      })

      this.$store.dispatch('getSystemRoles').then(response => {
        this.optionsRoles = this.getRespPaginateData(response)
        this.optionsRoles.map(item => {
          item.name = item.name.replace('_', ' ')
          item.name = item.name.charAt(0).toUpperCase() + item.name.slice(1)
        })
      })
    },

    data(){
      return {
        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY'): '',
        status: this.filterGetParams.status ? this.filterGetParams.status : '',
        balance: this.filterGetParams.balance ? this.filterGetParams.balance : '',
        frozen: this.filterGetParams.frozen ? this.filterGetParams.frozen : '',
        locked: this.filterGetParams.locked ? this.filterGetParams.locked : '',
        shopName: this.filterGetParams.shopName ? this.filterGetParams.shopName : '',

        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',

        country: this.filterGetParams.country ? this.filterGetParams.country : '',
        role: this.filterGetParams.role ? this.filterGetParams.role : '',
        lastLogin: this.filterGetParams.lastLogin ? this.filterGetParams.lastLogin : '',
        byFBMFee: this.filterGetParams.byFBMFee ? this.filterGetParams.byFBMFee : '',

        options: [],
        optionsLocked: [
          {name: this.$t('userModule_All.localization_value.value'), value: 'all'},
          {name: this.$t('userModule_Yes.localization_value.value'), value: '1'},
          {name: this.$t('userModule_No.localization_value.value'), value: '0'},
        ],

        LAST_LOGIN_DAYS_ARRAY: LAST_LOGIN_DAYS_ARRAY,

        optionsCountries: [],
        optionsRoles: [],

        // date: '',
        // name: '',
        // number: '',
        // user: '',
        // cellsFilter: '',
        // upc: '',
        // sku: '',
        // nameEE: '',

        filterCounts: [
          'userName',
          'userId',
          'date',
          'status',
          'balance',
          'frozen',
          'locked',
          'shopName',
          'country',
          'role',
          'lastLogin',
          'byFBMFee',
        ],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.status = newVal.status ? newVal.status : ''
        this.balance = newVal.balance ? newVal.balance : ''
        this.frozen = newVal.frozen ? newVal.frozen : ''
        this.locked = newVal.locked ? newVal.locked : ''
        this.shopName = newVal.shopName ? newVal.shopName : ''

        this.userName = newVal.userName ? newVal.userName : ''
        this.userId = newVal.userId ? newVal.userId : ''

        this.country = newVal.country ? newVal.country : ''
        this.role = newVal.role ? newVal.role : ''
        this.lastLogin = newVal.lastLogin ? newVal.lastLogin : ''
        this.byFBMFee = newVal.byFBMFee ? newVal.byFBMFee : ''
      },

    },

    methods: {

      changeOrderFbmFee(val) {
        this.byFBMFee = val?.value || ''
      },

      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.date = this.changeDateParams(this, 'date')

        this.$emit('changeFilter', data)
      },

      getCountrySelected(){
        if(this.country === '') return ''
        return this.optionsCountries.find(item => {
          return +this.country === +item.id
        }) || ''
      },

      getRoleSelected(){
        if(this.role === '') return ''
        return this.optionsRoles.find(item => {
          return +this.role === +item.id
        }) || ''
      },

      changeCountry(val) {
        this.country = val.id
      },

      changeRole(val) {
        this.role = val.id
      },

      getLastLoginSelected(){
        if(this.lastLogin === '') return ''
        return this.LAST_LOGIN_DAYS_ARRAY.find(item => {
          return +this.lastLogin === +item.value
        }) || ''
      },

      changeLastLogin(val) {
        this.lastLogin = val.value
      },
    },
  }
</script>

<style scoped>

</style>
