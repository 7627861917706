<template>
  <div class="fragment">
    <UsersTableAdmin
            v-if="$store.getters.getIsAdminRights === 'admin'"
            :countFilterParams="countFilterParams"
            :filterGetParams="filterGetParams"
            @resetFilter="resetFilter"
            @changeFilter="changeFilter"
            @showMore="showMore"
            @reload="reload"
            @disconnectAmazonAPI="disconnectAmazonAPI"
            @downloadFiles="downloadFiles"
    />
  </div>
</template>

<script>
  import UsersTableAdmin from "./UsersTableAdmin/UsersTableAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {DEFAULT_SHOP_TYPES} from "../../../../../staticData/staticVariables";

  export default {
    name: "UsersTable",

    components: {
      UsersTableAdmin,
    },

    mixins: [routeFilter],

    data() {
      return {
        forPage: this.$store.getters.getUsersForPage,
        page: 1,
        onePage: 1,
        filterId: '',
        filterUserName: '',
        filterUserId: '',
        filterStatus: '',
        filterBalance: '',
        filterFrozen: '',
        filterDate: '',
        filterLocked: '',
        filterShopName: '',
        filterCountry: '',
        filterRole: '',
        filterLastLogin: '',
        filterByFBMFee: '',

        countFilterParams: 0,
        filterGetParams: {},
      }
    },

    mounted() {
      this.filter()
    },

    methods: {
      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,

          id: this.$route.query.id,
          date: this.$route.query.date,
          status: this.$route.query.status,
          balance: this.$route.query.balance,
          frozen: this.$route.query.frozen,
          locked: this.$route.query.locked,
          shopName: this.$route.query.shopName,
          country: this.$route.query.country,
          role: this.$route.query.role,
          lastLogin: this.$route.query.lastLogin,
          byFBMFee: this.$route.query.byFBMFee,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextUsersPage', true)
        this.$store.dispatch('fetchUsers', url).then(() => {
          this.$store.commit('setNextUsersPage', false)
        })


        this.checkCountFilter(['userName'])
      },


      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        myQuery.includes('shops.shopType,shops.shopParam,userCounter')

        this.generateFilterQueryParams(
          myQuery,
          {
            filterUserId: 'UserId',
            // filterStatus: 'proform_entity_naming',
            filterBalance: 'UserBalance',
            filterFrozen: 'UserFrozenBalance',
            filterLocked: 'locked',
            filterShopName: 'shopName',
            filterCountry: 'country_id',
            filterLastLogin: 'lastLoginOver',
            filterByFBMFee: 'by_order_fbm_fee',
          },
        )

        myQuery.params({byRoleId: this.filterRole})

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
              'createdAt',
              date
          )
        }



        // url = url + myQuery.limit(forPage).page(parseInt(page)).url().split('?')[1]

        return this.generateGetParamsFromQuery(myQuery, forPage, page)
      },

      downloadFiles(type) {
        let forPage, page, url, exportIds

        exportIds = this.getCheckedRows('row-name')

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        url = this.generateFilterUrl(page, forPage, exportIds)
        if(url.indexOf('?') > -1) {
          url += '&ids=' + exportIds.join(',')
        } else {
          url += '?ids=' + exportIds.join(',')
        }

        this.$store.dispatch('getUserExport', {filter: url, exportType: type}).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            const blob = new Blob([response.data], {type: `application/${type}`})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = `Users.${type}`
            link.click()
            URL.revokeObjectURL(link.href)
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })
      },

      disconnectAmazonAPI() {
        let exportIds = this.getCheckedRows('row-name')
        if (exportIds.length === 0) return

        console.log(exportIds);
        let data = {
          'users': exportIds.map(item => { return {id: item} }),
          'shop_type_id': DEFAULT_SHOP_TYPES.amazonmws.id,
          'enable': false,
        }
        this.$store.dispatch('setShopsAmazonAPIForUsers', data).then(response => {
          if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;

              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },

    },

  }
</script>

<style scoped>

</style>
