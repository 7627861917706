
<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line ">
      <div class="content-tabs content-tabs--empty">

      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton
                :count="countFilterParams"
                @toggleFilter="$emit('toggleFilter')"
                :showFilter="showFilter"
        />
      </div>
    </div>

    <div class="light-bg-block">

      <div class="btn-left-block">
        <div class="btn-left-block-i"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['common_selectAll'])"></span>
          <DefaultCheckbox
              :label="$t('common_selectAll.localization_value.value')"
              @input="(val) => $emit('selectAll', val)"
          />
        </div>

        <div v-if="isAdmin" class="btn-left-block__list"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['userModule_disconnectAmazonAPI'])"></span>
          <div class="btn-left-block__list__i">
            <TopTableBoxesBtn
                class="btn-left-block-i--link"
                :title="'userModule_disconnectAmazonAPI'"
                @click.native="$emit('disconnectAmazonAPI')"
            />
          </div>
        </div>
      </div>

      <div class="btn-right-block">
        <div class="content-top-line-wrap ml-2"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'common_userReportOnDate',
        ])"></span>
          <TableGeadButton
              :value="$t('common_userReportOnDate.localization_value.value')"
              :ico="'print'"
              @click.native="userReportOnDatePopup = true"
          />
        </div>
        <div
            v-if="!isSubAdmin"
            v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['userModule_AddSubadmin'])"></div>

          <router-link :to="$store.getters.GET_PATHS.mainSettingsManageUsersLinkCreate + '?type=admin'">
            <MainButton class="btn-fit-content ml-2"
                        :value="$t('userModule_AddSubadmin.localization_value.value')"
            />
          </router-link>
        </div>
      </div>
    </div>

    <UserReportOnDatePopup
        v-if="userReportOnDatePopup"
        @close="userReportOnDatePopup = false"
    />
  </div>
</template>

<script>
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import TopTableBoxesBtn from "../../../../../../coreComponents/Buttons/TopTableBoxesBtn/TopTableBoxesBtn";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import TableGeadButton from "@/components/coreComponents/TableComponents/TableHeadButton/TableHeadButton";
  import UserReportOnDatePopup
    from "@/components/modules/UsersModule/popups/UserReportOnDatePopup/UserReportOnDatePopup";
  // import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  // import PrintButton from "../../../../../../UI/buttons/PrintButton/PrintButton";
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";


  export default {
    name: "UsersHead",
    components: {
      UserReportOnDatePopup,
      TableGeadButton,
      DefaultCheckbox,
      TopTableBoxesBtn,
      MainButton,
      ToggleFilterButton,
      // PlusIconSVG,
      // PrintButton,
      // DefaultCheckbox,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
    },

    watch: {

    },

    created() {

    },

    data() {
      return {
        isShowSearchEngineBlock: false,
        searchField1: '',
        searchField2: '',
        searchField3: '',
        searchField4: '',
        userReportOnDatePopup: false,
      }
    },



    methods: {


      changeTab(val) {
        this.$emit('changeTab', val)
      },

      selectAll(val) {
        this.$emit('selectAll', val)
      },

      showSearchEngineBlock(){
        this.isShowSearchEngineBlock = !this.isShowSearchEngineBlock;
      },
    }
  }
</script>

